import { RemixBrowser } from '@remix-run/react';
import { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from "@emotion/cache";

const hydrate = () => {
  const emotionCache = createEmotionCache({ key: "css" });

  startTransition(() => {
    hydrateRoot(
      document,
      <CacheProvider value={emotionCache}>
        <RemixBrowser />
      </CacheProvider>,
    )
  })
}

if (ENV.NODE_ENV === 'production' && ENV.SENTRY_DSN) {
  import('./utils/monitoring.client.tsx').then(({ init }) => init())
}

if (typeof window.requestIdleCallback === 'function') {
  window.requestIdleCallback(hydrate)
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  setTimeout(hydrate, 1)
}
